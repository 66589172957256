import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import WebApp from '@twa-dev/sdk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownDivider, DropdownItem } from 'flowbite-react';


const TopicsPage = ({ messageId }) => {
    const [topics, setTopics] = useState([]);
    const [newTopicName, setNewTopicName] = useState('');
    const [isCreatingTopic, setIsCreatingTopic] = useState(false);
    const searchInputRef = useRef(null);
    const creatingTopicInputRef = useRef(null);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchIconClick = () => {
        setIsSearchVisible(!isSearchVisible);
      };

    const handleSearchCloseClick = () => {
        setIsSearchVisible(false);
    };

    useEffect(() => {
        fetchTopics();
    }, []);

    useEffect(() => {
        if (isSearchVisible && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isSearchVisible]);
    
    useEffect(() => {
        if (isCreatingTopic && creatingTopicInputRef.current) {
            creatingTopicInputRef.current.focus();
        }
    }, [isCreatingTopic]);

    useEffect(() => {
        if (searchQuery) {
            fetch(`/api/topics/search/${messageId}?query=${searchQuery}`, {
                headers: {
                  "initData": WebApp.initData
                }
              })
                .then(response => response.json())
                .then(data => {
                    setTopics(data);
                });
        } else {
            fetchTopics()
        }
    }, [searchQuery, messageId]);

    function TopicsDropdown({ topic, messageId }) {
        return (
            <Dropdown label="" dismissOnClick={true} className="rounded-xl shadow-md focus:outline-none border-none outline-none" outline="none" style={{
                outline: 'none'}} renderTrigger={() => <FontAwesomeIcon icon={faEllipsis} style={{color: WebApp.themeParams.text_color}} />}>
            <DropdownItem className='bg-opacity-0' style={{
                outline: 'none'}} onClick={() => renameTopic(topic, messageId)}>Переименовать</DropdownItem>
            <DropdownItem className='bg-opacity-0' style={{
                outline: 'none'}} onClick={() => setMessageAsTopicStart(topic, messageId)}>Точка входа</DropdownItem>
            <DropdownDivider />
            <DropdownItem className='bg-opacity-0' style={{
                color: 'red',
                outline: 'none'}} onClick={() => deleteTopic(topic)}>Удалить</DropdownItem>
          </Dropdown>
        );
    }

    const fetchTopics = async () => {
        const response = await axios.get(`/api/topics/all/${messageId}`, {
            headers: {
                "initData": WebApp.initData
            }
        });
        setTopics(response.data);
    };

    const handleTopicClick = async (topicId) => {
        await axios.post('/api/topics/update_message_topic', {
            message_id: messageId,
            topic_id: topicId
        }, {
            headers: {
                "initData": WebApp.initData
            }
        });
        fetchTopics();
        WebApp.close();
    };

    const handleNewTopicSubmit = async (e) => {
        e.preventDefault();
        const trimmedTopicName = newTopicName.trim();
        if (trimmedTopicName.length < 1 || trimmedTopicName.length > 70) {
            alert('Название темы должно содержать от 1 до 70 символов.');
            return;
        }
        const confirmResult = window.confirm(`Вы действительно хотите создать тему «${trimmedTopicName}»?`);
        if (confirmResult) {
            await axios.post('/api/topics/create', {
                name: trimmedTopicName,
                message_id: messageId
            }, {
                headers: {
                    "initData": WebApp.initData
                }
            });
            setNewTopicName('');
            setIsCreatingTopic(false);
            fetchTopics();
        } else {
            setNewTopicName('');
            setIsCreatingTopic(false);  
        }
    };

    const renameTopic = async (topic, messageId) => {
        const newTopicName = window.prompt('Введите новое название темы:', topic.name);
        if (newTopicName) {
            try {
                await axios.post(`/api/topics/edit`, {
                    topic_id: topic.topic_id,
                    message_id: messageId,
                    topic_name: newTopicName
                }, {
                    headers: {
                        "initData": WebApp.initData
                    }
                });
                handleTopicClick(topic.topic_id);
            } catch (error) {
                alert('Произошла ошибка при переименовании темы.');
            }
        }
    };

    const setMessageAsTopicStart = async (topic, messageId) => {
        const confirmResult = window.confirm(`Вы действительно хотите установить точку входа для темы «${topic.name}»?`);
        if (!confirmResult) {
            return;
        }
        try {
            await axios.post(`/api/topics/edit`, {
                topic_id: topic.topic_id,
                message_id: messageId,
                set_as_entry_message: true
            }, {
                headers: {
                    "initData": WebApp.initData
                }
            });
            handleTopicClick(topic.topic_id);
        } catch (error) {
            alert('Произошла ошибка при установке точки входа.');
        }
    };


    const deleteTopic = async (topic) => {
        const confirmDelete = window.confirm(`Вы действительно хотите удалить тему «${topic.name}»?`);
        if (confirmDelete) {
          try {
            await axios.delete(`/api/topics/${topic.topic_id}`, {
                headers: {
                    "initData": WebApp.initData
                }
            });
            fetchTopics();
          } catch (error) {
            alert('Произошла ошибка при удалении темы.');
          }
        }
      };

    return (
        <div>
            <div className="pt-5 px-10 text-right">
                {isSearchVisible ? (
                    <div>
                        <input 
                            ref={searchInputRef} 
                            type="text" 
                            placeholder="Поиск" 
                            style={{                     
                                outline: 'none',
                                backgroundColor: WebApp.themeParams.bg_color,
                                color: WebApp.themeParams.text_color,
                                borderColor: WebApp.themeParams.secondary_bg_color
                            }}
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                        <FontAwesomeIcon icon={faTimes} onClick={handleSearchCloseClick} style={{color: WebApp.themeParams.text_color}} />
                    </div>
                ) : (
                    <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearchIconClick} style={{color: WebApp.themeParams.text_color}} />
                )}
            </div>
            <div className="px-4">
                {topics.map(topic => (
                    <div 
                        key={topic.topic_id} 
                        onClick={() => handleTopicClick(topic.topic_id)}
                        className="flex items-center justify-between rounded-xl shadow-lg p-6 my-4 cursor-pointer"
                        style={{
                            backgroundColor: WebApp.themeParams.bg_color,
                            color: WebApp.themeParams.text_color,
                            borderColor: WebApp.themeParams.secondary_bg_color,
                            fontWeight: 'bold', // Add this line to make the text bold
                            strokeWidth: '2px',
                            stroke: WebApp.themeParams.secondary_bg_color
                        }}
                    >
                        {topic.name}
                        <button 
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering the click event on the parent div
                                <TopicsDropdown topic={topic} messageId={messageId} />
                            }} 
                            style={{
                                color: WebApp.themeParams.text_color
                            }}
                        >
                            <TopicsDropdown topic={topic} messageId={messageId} />
                        </button>
                    </div>
                ))}
                {isCreatingTopic ? (
                    <form onSubmit={handleNewTopicSubmit} className="mt-4">
                        <input
                            ref={creatingTopicInputRef}
                            type="text"
                            value={newTopicName}
                            onChange={e => setNewTopicName(e.target.value)}
                            placeholder="Название темы"
                            className="border rounded-lg p-2 w-full"
                            style={{                     
                                outline: 'none',
                                backgroundColor: WebApp.themeParams.bg_color,
                                color: WebApp.themeParams.text_color,
                                borderColor: WebApp.themeParams.secondary_bg_color,
                                padding: '5px',
                                marginBottom: '10px'
                            }}
                        />
                        <button
                            type="submit" 
                            className="font-bold py-2 px-4 rounded-lg"
                            style={{
                                border: 'none',
                                outline: 'none',
                                cursor: 'pointer',
                                width: '100%',  // Установите ширину на 100% для использования всего доступного пространства
                                maxWidth: '600px',  // Ограничьте максимальную ширину, чтобы форма не была слишком широкой на больших экранах,
                                color: WebApp.themeParams.button_text_color,
                                backgroundColor: WebApp.themeParams.button_color
                            }}>
                                Создать
                        </button>
                    </form>
                ) : (
                    <button onClick={() => setIsCreatingTopic(true)} 
                        className="font-bold py-2 px-4 rounded-lg"
                        style={{
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer',
                            width: '100%',  // Установите ширину на 100% для использования всего доступного пространства
                            maxWidth: '600px',  // Ограничьте максимальную ширину, чтобы форма не была слишком широкой на больших экранах,
                            color: WebApp.themeParams.button_text_color,
                            backgroundColor: WebApp.themeParams.button_color
                        }}>
                            Создать тему
                    </button>
                )}
            </div>
        </div>
    );
};

export default TopicsPage;