import React, { useEffect, useState } from "react";
import axios from "axios";
import WebApp from "@twa-dev/sdk";
import { Dropdown } from "rsuite";
import { DateRangePicker } from "rsuite";
import Button from "rsuite/Button";

import "rsuite/Button/styles/index.css";
import "rsuite/Dropdown/styles/index.css";
import "rsuite/DateRangePicker/styles/index.css";

const { allowedRange } = DateRangePicker;

const ReportPage = () => {
  const [feeds, setFeeds] = useState([]);
  const [selectedFeed, setSelectedFeed] = useState("Тема отчета");
  const [reportFormat, setReportFormat] = useState('word');
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const PickerLocale = {
    sunday: "Вс",
    monday: "Пн",
    tuesday: "Вт",
    wednesday: "Ср",
    thursday: "Чт",
    friday: "Пт",
    saturday: "Сб",
    ok: "OK",
    today: "Сегодня",
    yesterday: "Вчера",
    hours: "Часы",
    minutes: "Минуты",
    seconds: "Секунды",
    title: "Выберите дату",
    placeholder: "Выберите дату",
    last7Days: "Последние 7 дней",
  };

  const handleReportRequest = async () => {
    // устанавливаем флаг блокировки кнопки
    setDisabled(true);
    if (selectedFeed === "Тема отчета") {
      alert("Выберите тему отчета");
      return;
    }
    if (!dateRange) {
      alert("Выберите период отчета");
      return;
    }
    const requestDateFrom = dateRange[0].toISOString().split("T")[0];
    const requestDateTo = dateRange[1].toISOString().split("T")[0];
    const response = await fetch(
      `/api/report/generate?feed=${selectedFeed}&startDate=${requestDateFrom}&endDate=${requestDateTo}&report_format=${reportFormat}`,
      {
        headers: {
          initData: WebApp.initData,
        },
      },
    );
    if (response.ok) {
      alert("Отчет успешно сформирован и отправлен в диалог");
    } else {
      alert("Ошибка при формировании отчета");
    }
    setDisabled(false);
  };

  useEffect(() => {
    axios
      .get("/api/feed/all", {
        headers: {
          initData: WebApp.initData,
        },
      })
      .then((response) => {
        setFeeds(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Замените этот div на ваш компонент лоадера
  }

  return (
    <div
      className="p-4"
      style={{
        backgroundColor: WebApp.themeParams.bg_color,
        color: WebApp.themeParams.text_color,
      }}
    >
      <div
        className="m-4 mx-auto max-w-md rounded-xl shadow-md md:max-w-2xl"
        style={{
          backgroundColor: WebApp.themeParams.bg_color,
          color: WebApp.themeParams.text_color,
        }}
      >
        <div className="md:flex">
          <div className="px-5 pt-5">
            <Dropdown title={selectedFeed}>
              {feeds.map((feed) => (
                <Dropdown.Item
                  key={feed.name}
                  onClick={() => setSelectedFeed(feed.name)}
                >
                  {feed.name}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
        </div>
        <div className="px-5 py-4">
          <Dropdown title={reportFormat}>
            <Dropdown.Item onClick={() => setReportFormat('word')}>Word</Dropdown.Item>
            <Dropdown.Item onClick={() => setReportFormat('excel')}>Excel</Dropdown.Item>
          </Dropdown>
        </div>
        <div className="px-5 pb-5">
          <DateRangePicker
            showOneCalendar
            placeholder="Период отчета"
            format="dd.MM.yyyy"
            character=" — "
            locale={PickerLocale}
            isoWeek={true}
            shouldDisableDate={allowedRange("2024-02-12", Date())}
            onOk={(value) => setDateRange(value)}
          />
        </div>
        <div className="px-5 pb-5">
          <Button onClick={handleReportRequest} disabled={disabled}>
            Запросить отчет
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
