import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import WebApp from '@twa-dev/sdk'
import 'moment/locale/ru';

moment.locale('ru');



function CommentsPage({ postId }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchComments = async () => {
      const response = await axios.get(`/api/comments/${postId}`, {
        headers: {
          "initData": WebApp.initData
        }
      });
      setComments(response.data);
    };
    fetchComments();
  }, [postId]);
  
  const fetchComments = async () => {
    const response = await axios.get(`/api/comments/${postId}`, {
      headers: {
        "initData": WebApp.initData
      }
    });
    setComments(response.data);
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setIsLoading(true);

    if (newComment.length < 1 || newComment.length > 1000) {
      alert('Длина комментария должна быть от 1 до 1000 символов.');
      return;
    }
    try {
      const response = await axios.post(`/api/comments/post`, {
        message_id: postId,
        text: newComment,
      }, {
        headers: {
          "initData": WebApp.initData
        }
      });
    
      if (response.status === 200) {
        setNewComment('');
        fetchComments();
      } else {
        alert('Произошла ошибка при добавлении комментария.');
      }
      setIsLoading(false);
    } catch (error) {
      alert('Произошла ошибка при добавлении комментария.');
      setIsLoading(false);
    }
  };

  return (
    <div style={{ backgroundColor: WebApp.themeParams.bg_color, padding: '20px', borderRadius: '5px'}}>
        {comments.map((comment) => (
            <div key={comment.comment_id} style={{ marginBottom: '10px' }}>
                <a href={`tg://user?id=${comment.author_telegram_id}`} style={{ textDecoration: 'none', color: WebApp.themeParams.text_color }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>{comment.author_name}</h2>
                </a>
                <p style={{ fontSize: '14px', marginBottom: '5px', color: WebApp.themeParams.text_color }}>{comment.text}</p>
                <p style={{ fontSize: '12px', color: WebApp.themeParams.hint_color }}>
                    {moment.utc(comment.created_at).local().fromNow()}
                </p>
            </div>
        ))}
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <textarea
                type="text"
                className="border rounded-lg p-2 w-full"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Оставить комментарий"
                style={{ 
                    marginBottom: '5px', 
                    padding: '5px', 
                    width: '100%',  // Установите ширину на 100% для использования всего доступного пространства
                    maxWidth: '600px',  // Ограничьте максимальную ширину, чтобы форма не была слишком широкой на больших экранах
                    backgroundColor: WebApp.themeParams.bg_color,
                    color: WebApp.themeParams.text_color,
                    borderColor: WebApp.themeParams.secondary_bg_color,
                    outline: 'none'
                }}
            />
            <button 
                type="submit" 
                className={`font-bold py-2 px-4 rounded-lg`}
                disabled={isLoading}
                style={{
                    border: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                    width: '100%',  // Установите ширину на 100% для использования всего доступного пространства
                    maxWidth: '600px',  // Ограничьте максимальную ширину, чтобы форма не была слишком широкой на больших экранах,
                    color: WebApp.themeParams.button_text_color,
                    backgroundColor: WebApp.themeParams.button_color
                }}
            >
                {isLoading ? 'Отправка...' : 'Отправить'}
            </button>
        </form>
    </div>
  );
}

export default CommentsPage;