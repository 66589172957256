import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import CommentsPage from './components/CommentsPage';
import TopicsPage from './components/TopicsPage';
import SourcePage from './components/SourcePage';
import ReportPage from './components/ReportPage';
import WebApp from '@twa-dev/sdk'

function App() {
  return (
    <Router>
      <div style={{ backgroundColor: WebApp.themeParams.bg_color, minHeight: '100vh' }}>
        <Routes>
          {/* Другие маршруты */}
          <Route path="*" element={<Wrapper />} />
        </Routes>
      </div>
    </Router>
  );
}

function Wrapper() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tgWebAppStartParam = params.get('tgWebAppStartParam');

  if (!tgWebAppStartParam) {
    return <div className="flex items-center justify-center h-screen">
        <div className="rounded-lg p-8 shadow-lg">
          <p className="text-center">Приложение недоступно</p>
        </div>
      </div>
    ;
  }

  const postId = tgWebAppStartParam.split('_')[1];
  const command = tgWebAppStartParam.split('_')[0];

  if (command === 'comment') {
    return <CommentsPage postId={postId} />;
  } else if (command === 'topics') {
    return <TopicsPage messageId={postId} />;
  } else if (command === 'source') {
    return <SourcePage messageId={postId} />;
  } else if (command === 'report') {
    return <ReportPage />;
  } else {
    return <div className="flex items-center justify-center h-screen">
        <div className="rounded-lg p-8 shadow-lg">
          <p className="text-center">Приложение недоступно</p>
        </div>
      </div>
    ;
  }
}

export default App;